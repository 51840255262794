import {getGlobalParams} from '@/views/homePage/common'
import v from '../main'

export default {
	login: '/management-api/v1/Sessions', //登录
	getChinaRegions: '/common-api/v1/ChinaRegions.json', //获取省市区数据
	userInfo: '/management-api/v1/UserProfiles/~', //人员信息详情
	password: '/management-api/v1/Passwords/~.json', //修改密码
	getChinaRegion: '/common-api/v1/ChinaRegions.json',
	getMedicalOrganizations: '/management-api/v1/MedicalOrganizations', //获取医疗机构列表
	getDictionaryItems: '/common-api/v1/DictionaryItems.json', //获取字典项
	getAttachmentGroups: '/common-api/v1/AttachmentGroups.json', //获取附件组
	getVehicles: '/management-api/v1/Vehicles', //获取车辆列表
	getDevices: '/management-api/v1/Devices', //获取设备列表
	getTrainings: '/management-api/v1/Trainings', //获取培训列表
	getMedicalWorkers: '/management-api/v1/MedicalWorkers', //获取医护人员
	getMedicalWorkerGroups: '/management-api/v1/MedicalWorkerGroups', //巡诊驻派组
	getDictionaries: '/common-api/v1/Dictionaries.json', //数据字典信息列表
	getModules: '/common-api/v1/DictionaryItems', //获取模块
	getTourPlans: '/management-api/v1/TourPlans', //计划创建
	getPeople: '/management-api/v1/People', //人员列表getMedicalWorkers
  getPerformance:'/management-api/v1/PerformanceEvaluationResults.json' //绩效考核
};

export const fetchRegionSummaryNotations = ({itemName, fullTime, descendantLevel, operators="sum"}) => {
  const globalParams = getGlobalParams()
  let params = {}
  if(fullTime){
    params = { ...globalParams, itemName: itemName.split(';').filter(i => i).map(n => n + '增量').join(';') + ';' }
  }else {
    params = { regionCode: globalParams.regionCode, itemName}
  }
  if(descendantLevel){
    params.descendantLevel = 1
  }
  if(operators){
    params.operators = operators
  }
  return v.$http.get('/management-api/v1/RegionSummaryNotations.json', {params})
}

export const mapDataNormal = (originData, collections) => {
  originData.forEach(d => {
    d.value = collections.find(c => c.name.includes(d.name))?.intValue ?? 0
  })
  return originData
}

export const mapData = (kvMap, collections) => {
  const result = []
  Object.keys(kvMap).forEach(k => {
    result.push({name: k, value: collections?.find(c => c.name === kvMap[k])?.intValue ?? 0})
  })
  return result
}

export const findValueByName = (name, collections) => {
  return collections?.find(c => c.name.includes(name))?.intValue ?? 0
}

export const mergeValueByRegion = (collections, mapmap) => {
  const result = []

  const updateOrCreate = (item) =>{
    const tryFind = result.find(r => r.name === item.chinaRegionDto.title)
    if(tryFind){
      return tryFind
    }else {
      const obj = {name: item.chinaRegionDto.title}
      mapmap.forEach(mm => {
        obj[mm.as] = 0
      })
      result.push(obj)
      return obj
    }
  }

  collections.forEach(c => {
    const d = updateOrCreate(c)
    const findMap = mapmap.find(m => {
      return c.name.includes(m.from)
    })
    d[findMap.as] = c.intValue
  })
  return result
}