<template>
  <div class="bordered mt16">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">工作指标</Title>
          <div v-for="(item,index) in indicatorsList1ByLevel" class="item" v-if="isService">
            <p>{{ item.label }}</p>
            <span v-if="index==0||index==1||index==4"> <countTo :startVal="0" :endVal="item.value" :duration="CountAnimationDuration"></countTo></span>
            <span v-else> <countTo :startVal="0" :endVal="item.per" :duration="CountAnimationDuration"></countTo>%</span>
          </div>
          <div v-for="(item,index) in indicatorsList2ByLevel" class="item" v-if="!isService">
            <p>{{ item.label }}</p>
            <span v-if="index==0||index==3"><countTo :startVal="0" :endVal="item.value" :duration="CountAnimationDuration"></countTo></span>
            <span v-else><countTo :startVal="0" :endVal="item.per" :duration="CountAnimationDuration"></countTo>%</span>
          </div>
    <div class="tab">
		<span :class="serviceIndex==1?'on':''" @click="selectService(1)">高血压</span>
		<em>|</em>
		<span :class="serviceIndex==2?'on':''" @click="selectService(2)">糖尿病</span>
	  </div>
  </div>
</template>
<script>
import Title from '../components/Title';
import { mapState } from 'vuex';
import { random } from '@/utils'
import countTo from 'vue-count-to';
import {CountAnimationDuration} from '@/views/homePage/common'
export default {
  components: { Title,countTo },
  data() {
    return {
      CountAnimationDuration,
      indicatorsList1:[
        {label:'登记数量',value:1098,per:80},
        {label:'高危患者数量',value:1145,per:70},
        {label:'血压测试比例',value:1098,per:60},
        {label:'高血压防治知识知晓率',value:1098,per:50},
        {label:'服务评价',value:2109,per:80},
      ],
      indicatorsList2:[
        {label:'登记数量',value:1198,per:32},
        {label:'抽查率',value:1145,per:32},
        {label:'血糖控制率',value:1098,per:80},
        {label:'高危患者数量',value:2864,per:80},
        {label:'血糖测试比例',value:2109,per:63},
      ],
      serviceIndex:1,
      isService:true,
      isShowSwitch:true,
      isSwitchOpen:true,
      period:1
    };
  },
  mounted() {
    this.selectTime()
  },
  methods: {
    init() {
    },
    selectService(i){
		this.serviceIndex=i
		if(i==1){
			this.isService=true
      this.$store.state.homePage.block.workIndicators = this.indicatorsList1ByLevel[0].value
		}else{
			this.isService=false
      this.$store.state.homePage.block.workIndicators = this.indicatorsList2ByLevel[0].value
		}
	  },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.selectTime()
    },
    curveFun(name){
    },
    selectTime(){
      if(this.isSwitchOpen){
        this.period=this.monthPeriod
      }else{
        this.period=this.currentMonthPeriod
      }
      this.init()
    }
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    indicatorsList1ByLevel(){
      return this.indicatorsList1.map(item=>{
        return{
          ...item,
          value:Math.round(item.value/(this.mapLevel + 1)/this.period),
          per:Math.round(item.per-(4*(this.mapLevel + 1))/this.period)
        }
      })
    },
    indicatorsList2ByLevel(){
      return this.indicatorsList2.map(item=>{
        return{
          ...item,
          value:Math.round(item.value/(this.mapLevel + 1)/this.period),
          per:Math.round(item.per-(4*(this.mapLevel + 1))/this.period)
        }
      })
    },
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch:{
    mapLevel:{
      immediate:true,
      handler:function(){
      }
    },
    monthPeriod:{
     handler(){
      this.selectTime()
     },
    },
    indicatorsList1ByLevel:{
      handler(val){
        this.$store.state.homePage.block.workIndicators = val[0].value
      },
      immediate:true
    }
  }
};
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  height: 412px;
  .item{
    width: 90%;
    height: 48px;
    line-height: 48px;
    background-color: #072B5C;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin:16px auto;
    align-items: center;
    p{
      font-size: 18px;
      font-weight: 400;
    }
    span{
      font-size: 26px;
      font-weight: 500;
      color: #00FFFF;
    }
  }
  .tab{
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	border-top: 1px solid #213861;	
	margin-top: 5px;
	span{
		display: inline-block;
		width: 205px;
		height: 100%;
		position: relative;
		cursor: pointer;
	}
	em{
		font-style: normal;
		color: #213861;
	}
	.on{
		color: #00F0FF;
	}
} 
}
</style>