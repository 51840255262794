<template>
  <div class="bordered">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch" @curveFun="curveFun">服务体系</Title>
    <div class="echarts" v-if="isService">
      <div class="dashboard">
        <BaseEchart :option="option2" style="width: 100%; height: 100%"></BaseEchart>
        <img src="../../../assets/imgs/dashboard.png" alt="" />
        <div class="circle">
          <span class="txt">{{ dashValue1 }}<span class="suffix">%</span></span>
        </div>
        <div class="title">
          <span>社区覆盖率</span>
        </div>
      </div>
      <div class="echart_right">
        <div v-for="item in serviceSys">
          <p>{{ item.label }}</p>
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
    <div class="secondtab" v-else>
      <div class="echart_top">
        <p>随访记录数</p>
        <span>3579</span>
      </div>
      <div class="echarts_2">
        <div class="dashboard">
          <BaseEchart :option="option2" style="width: 100%; height: 100%"></BaseEchart>
          <img src="../../../assets/imgs/dashboard.png" alt="" />
          <div class="circle">
            <span class="txt">{{ dashValue1 }}<span class="suffix">%</span></span>
          </div>
          <div class="title">
            <span>随访发现率</span>
          </div>
        </div>
        <div class="dashboard">
          <BaseEchart :option="option2" style="width: 100%; height: 100%"></BaseEchart>
          <img src="../../../assets/imgs/dashboard.png" alt="" />
          <div class="circle">
            <span class="txt">{{ dashValue1 }}<span class="suffix">%</span></span>
          </div>
          <div class="title">
            <span>有效随访率</span>
          </div>
        </div>
      </div>
    </div>

    <div class="tab">
      <span :class="serviceIndex == 1 ? 'on' : ''" @click="selectService(1)">服务建设</span>
      <em>|</em>
      <span :class="serviceIndex == 2 ? 'on' : ''" @click="selectService(2)">随访</span>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title.vue';
import '@/assets/icon-font/ABDUCTION2000.css';
import BaseEchart from '@/components/BaseEchart';
import { random } from '@/utils';
import { mapState } from 'vuex';
import { CountAnimationDuration } from '@/views/homePage/common';
import * as echarts from 'echarts';
import { fetchRegionSummaryNotations, findValueByName } from '../../../request/api';


export default {
  components: { Title, BaseEchart },
  data() {
    return {
      option2: undefined,
      CountAnimationDuration,
      serviceSys: [
        {
          label: '社区服务中心',
          value: 0,
        },
        {
          label: '医护人员总数',
          value: 0,
        },
        {
          label: '健康档案管理',
          value: 0,
        },
      ],
      serviceIndex: 1,
      dashValue1: 0,
      isService: true,
      isShowSwitch: true,
      isSwitchOpen: true,
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    timeRange() {
      return this.$store.state.homePage.timeRange;
    },
  },
  methods: {
    async init() {
      this.dashValue1 = (this.mapLevel + 1) * random(10, 20);
      this.option2 = this.genDashChartOption(this.dashValue1);
      const data = await fetchRegionSummaryNotations({ itemName: '健康档案数量;社区中心;服务体系-医生数量', fullTime: this.isSwitchOpen })
      let res = data.data.collection
      this.serviceSys[2].value = findValueByName('健康档案数量', data?.data?.collection)
      this.serviceSys[1].value = findValueByName('医生数量', data?.data?.collection)
      this.serviceSys[0].value = findValueByName('社区中心', data?.data?.collection)
      let num = 0
      res.map(item => {
        num += item.intValue
      })
      this.$store.state.homePage.block.serviceSys = num
    },
    genDashChartOption(value) {
      let nqColor = [
        [
          value / 100,
          new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            {
              offset: 0,
              color: 'rgba(86, 212, 212,1)',
            },
            {
              offset: 1,
              color: 'rgba(74, 156, 156, 0.7) ',
            },
          ]),
        ],
        [
          1,
          new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            {
              offset: 0,
              color: 'rgba(97, 206, 206, 0.3)',
            },
            {
              offset: 1,
              color: 'rgba(97, 206, 206, 0.3)',
            },
          ]),
        ],
      ];
      return {
        backgroundColor: '#021434',
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%',
        },
        series: [
          {
            name: '最外部进度条',
            type: 'gauge',
            radius: '82%',
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                color: [
                  [
                    100,
                    new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: 'rgba(145,207,255,0)',
                      },
                      {
                        offset: 0.5,
                        color: 'rgba(74, 156, 156, 0.7)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(86, 212, 212,1)',
                      },
                    ]),
                  ],
                  [1, 'rgba(28,128,245,.0)'],
                ],
                width: 3,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            itemStyle: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: 'title',
                value: value,
              },
            ],
            pointer: {
              show: false,
            },
            animationDuration: 4000,
          },
          {
            name: '内部圈',
            type: 'gauge',
            z: 2,
            min: 0,
            max: 10,
            splitNumber: 10,
            radius: '75%',
            axisLine: {
              show: true,
              lineStyle: {
                width: 13,
                color: nqColor,
              },
            },
            tooltip: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            detail: {
              show: false,
            },
            title: {
              //标题
              show: false,
            },
            data: [
              {
                name: 'title',
                value: value / 10,
              },
            ],
            itemStyle: {
              show: false,
              normal: {
                color: 'rgba(86, 212, 212,1)',
              },
            },
            pointer: {
              show: true,
              length: '80%',
              radius: '20%',
              width: 6, //指针粗细
            },
            animationDuration: 1000,
          },
          {
            name: '内部刻度',
            type: 'gauge',
            radius: '60%',
            min: 0, //最小刻度
            max: 10, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: false,
              lineStyle: {
                width: 5,
                color: 'rgba(86, 212, 212,1)',
              },
            }, //刻度标签。
            axisLabel: {
              show: false,
              fontSize: 14,
            },
            axisTick: {
              show: true,
              splitNumber: 7,
              lineStyle: {
                color: 'rgba(86, 212, 212,1)', //用颜色渐变函数不起作用
                width: 1,
              },
              length: 4,
            }, //刻度样式
            splitLine: {
              show: true,
              length: 8,
              lineStyle: {
                color: '#1C3164', //用颜色渐变函数不起作用
              },
            }, //分隔线样式
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
          {
            //指针上的圆
            type: 'pie',
            tooltip: {
              show: false,
            },
            hoverAnimation: false,
            legendHoverLink: false,
            radius: ['0%', '20%'],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: value / 10,
                itemStyle: {
                  normal: {
                    color: 'rgb(14,31,73)',
                  },
                },
              },
            ],
          },
        ],
      };
    },
    selectService(i) {
      this.serviceIndex = i;
      if (i == 1) {
        this.isService = true;
      } else {
        this.isService = false;
        this.$store.state.homePage.block.serviceSys = this.dashValue1
      }
    },
    changeSwitch(val) {
      this.isSwitchOpen = val;
      this.init();
    },
    curveFun(name) {
    },
  },
  watch: {
    mapDataArr: {
      immediate: true,
      handler() {
        this.init();
      },
    },
    timeRange() {
      this.init()
    }
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  height: 320px;
  display: flex;
  flex-direction: column;
  // background: rgba(5, 41, 92, .6);
  // border: 2px solid rgba(17, 94, 205, .2);
}

.echarts {
  display: flex;
  width: 100%;
  height: 239px;
  justify-content: space-around;

  .dashboard {
    width: 48%;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      width: 120px;
      top: 24%;
      left: 23%;
      z-index: 0;
    }

    .circle {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      background: radial-gradient(at top left, #157a9f94 30%, #073051e5 70%);
      position: absolute;
      top: 32%;
      left: 32%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #40a3bc;

      .txt {
        font-size: 30px;
        color: #afeef1;
      }
    }

    .suffix {
      color: #afeef1;
      font-size: 16px;
    }

    .title {
      width: 113px;
      height: 32px;
      background: #041935;
      border: 1px solid #00ddec;
      border-radius: 16px;
      position: absolute;
      top: 83%;
      left: 25%;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px 5px rgba(86, 212, 212, 0.7) inset;
    }
  }

  .echart_right {
    width: 170px;
    height: 100%;

    div {
      height: 60px;
      background-color: #0c3162;
      margin-top: 15px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        font-size: 14px;
        font-weight: 400;
      }

      span {
        font-size: 20px;
        font-weight: 500;
        color: #00ffff;
      }
    }
  }
}

.secondtab {
  display: flex;
  flex-direction: column;
  height: 270px;
  background-color: rgb(2, 20, 52);

  .echart_top {
    width: 90%;
    height: 35px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #0b3062;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    span {
      font-size: 20px;
      font-weight: 500;
      color: #00ffff;
    }
  }

  .echarts_2 {
    display: flex;
    width: 100%;
    height: 190px;
    justify-content: space-around;

    .dashboard {
      width: 48%;
      height: 100%;
      position: relative;

      img {
        position: absolute;
        width: 115px;
        top: 21%;
        left: 24%;
        z-index: 0;
      }

      .circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: radial-gradient(at top left, #157a9f94 30%, #073051e5 70%);
        position: absolute;
        top: 29%;
        left: 32%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #40a3bc;

        .txt {
          font-size: 30px;
          color: #afeef1;
        }
      }

      .suffix {
        color: #afeef1;
        font-size: 16px;
      }

      .title {
        width: 113px;
        height: 32px;
        background: #041935;
        border: 1px solid #00ddec;
        border-radius: 16px;
        position: absolute;
        top: 83%;
        left: 25%;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 10px 5px rgba(86, 212, 212, 0.7) inset;
      }
    }
  }
}

.tab {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #213861;
  margin-top: 5px;

  span {
    display: inline-block;
    width: 205px;
    height: 100%;
    position: relative;
    cursor: pointer;
  }

  em {
    font-style: normal;
    color: #213861;
  }

  .on {
    color: #00f0ff;
  }
}</style>
