<template>
	<div class="bordered mt16">
		<Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">业务培训</Title>
		<div class="content">
			<div class="leftEvent">
				<p class="leftEvent-title">
					<countTo :startVal="0" :endVal="valueByLevel" :duration="CountAnimationDuration"></countTo>
				</p>
				<p class="leftEvent-subTitle">培训总数</p>
			</div>
            <div class="middleEvent">
				<p class="middleEvent-title"><countTo :startVal="0" :endVal="valueByLevel" :duration="CountAnimationDuration"></countTo></p>
				<p class="middleEvent-subTitle">参与培训人数</p>
			</div>
			<div class="rightEvent">
				<p class="rightEvent-title"><countTo :startVal="0" :endVal="valueByLevel" :duration="CountAnimationDuration"></countTo></p>
				<p class="rightEvent-subTitle">参与培训人数</p>
			</div>
		</div>
	</div>
</template>
<script>
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import {CountAnimationDuration} from '@/views/homePage/common'
import { mapState } from 'vuex';

export default {
	components: { Title, countTo },
  data() {
    return {
      CountAnimationDuration,
	  value:3765873,
	  isShowSwitch:true,
      isSwitchOpen:true,
	  period:1
    }
  },
  computed:{
	...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    valueByLevel(){
      return Math.round(this.value / (this.mapLevel + 1)/this.period)
    },
	currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    mapDataArr() {
      // this.init()
    },
    monthPeriod:{
     handler(){
      this.selectTime()
     },
    },
	valueByLevel:{
      handler(val){
        this.$store.state.homePage.block.businessTraining = val
      },
      immediate:true
    }
  },
  mounted() {
	this.selectTime()
  },
  methods: {
	changeSwitch(val){
      this.isSwitchOpen=val
	  this.selectTime()
    },
    curveFun(name){
    },
	selectTime(){
      if(this.isSwitchOpen){
        this.period=this.monthPeriod
      }else{
        this.period=this.currentMonthPeriod
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  height: 180px;
}
.content {
	background: url('../../../assets/imgs/eventStatic.png') no-repeat center;
	background-size: 100% 100%;
	display: flex;
	justify-content: space-between;
	margin: 10px 16px 16px;
	div {
		width: calc(50% - 15px);
		text-align: center;
		margin-bottom: 30px;
		padding: 30px 0;
		p {
			font-size: 18px;
			color: #fff;
			line-height: 16px;
			height: 16px;
		}
		p:first-child {
			font-size: 29px;
			margin-bottom: 10px;
			line-height: 24px;
			height: 24px;
		}
	}
	.leftEvent {
		background: url('../../../assets/imgs/blueBg.png') no-repeat center;
		background-size: 100% 100%;
		&-title {
			color: #00f0ff;
		}
	}
    .middleEvent{
        background: url('../../../assets/imgs/greenBg.png') no-repeat center;
		background-size: 100% 100%;
		&-title {
			color: #00ECCD;
		}
    }
	.rightEvent {
		background: url('../../../assets/imgs/yellowBg.png') no-repeat center;
		background-size: 100% 100%;
		&-title {
			color: #ff9c66;
		}
	}
}
</style>
