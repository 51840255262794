<template >
	<div class="statistics">
		<div class="statistics-item" v-for="(item, index) in statisticsList" :key="item.label">
			<div class="line" v-if="index != 0"></div>
			<div class="label">{{ item.label }}</div>
			<div class="num">
				<countTo :startVal="0" :endVal="item.num" :duration="CountAnimationDuration"></countTo>
			</div>
		</div>
	</div>
</template>
<script>
import StatisticsBar from '../components/StatisticsBar.vue'
import { CountAnimationDuration } from '@/views/homePage/common';
import countTo from 'vue-count-to';
import { mapState } from 'vuex'
export default {
	components: { StatisticsBar, countTo },
	data() {
		return {
			CountAnimationDuration,
			statisticsList: [
				{ label: '基础数据', num: 12068 },
				{ label: '慢性病管理', num: 2068 },
				{ label: '教育宣传', num: 1023 },
				{ label: '服务体系', num: 512 },
				{ label: '工作指标', num: 2230 },
				{ label: '业务培训', num: 2230 },
			],
		}
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		statisticsListByLevel() {
			return this.statisticsList.map(s => {
				return {
					...s,
					num: s.num / (this.mapLevel + 1)
				}
			})
		},
		block() {
			return this.$store.state.homePage.block;
		},
	},
	watch: {
		block: {
			handler: function (val) {
				this.statisticsList = [
					{ label: '基础数据', num: val.basicData },
					{ label: '慢性病管理', num: val.diseaseManage },
					{ label: '教育宣传', num: val.eduPublicity },
					{ label: '服务体系', num: val.serviceSys },
					{ label: '工作指标', num: val.workIndicators },
					{ label: '业务培训', num: val.businessTraining },
				]
			},
			deep: true,
			immediate: true
		}
	}
}
</script>
<style lang="scss" scoped>
.bordered {
	background: rgba(5, 41, 92, .6);
	border: 2px solid rgba(17, 94, 205, .2);
}

.statistics {
	background: url('../../../assets/imgs/totalBgTop.png') no-repeat center;
	background-size: 100% 100%;
	height: 100px;
	padding: 20px 44px;
	display: flex;
	justify-content: space-between;

	&-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		// flex:1;
		width: auto;

		.label {
			font-size: 18px;
		}

		.num {
			color: #0bf9fe;
			font-weight: bold;
			font-style: italic;
			font-size: 28px;
		}

		.numyellow {
			color: #F5F971;
			font-weight: bold;
			font-style: italic;
			font-size: 28px;
		}

		.line {
			width: 1px;
			height: 29px;
			border: 1px solid #274574;
			position: absolute;
			left: -30px;
			top: 10px;
		}
	}
}
</style>