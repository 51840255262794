<template>
	<div class="statistics">
		<div class="statistics-item" v-for="(item, index) in data" :key="item.label">
			<div class="line" v-if="index != 0"></div>
			<div class="label">{{ item.label }}</div>
			<div class="num">
				<countTo :startVal="0" :endVal="item.num" :duration="CountAnimationDuration"></countTo>
			</div>
		</div>
	</div>
</template>
<script>
import { CountAnimationDuration } from '@/views/homePage/common';
import countTo from 'vue-count-to';

export default {
  components: {countTo},
  props: ['data'],
  data() {
    return {
      CountAnimationDuration
    }
  }
};
</script>
<style lang="scss" scoped>
.statistics {
	background: url('../../../assets/imgs/totalBgTop.png') no-repeat center;
	background-size: 100% 100%;
	height: 100px;
	padding: 20px 44px;
	display: flex;
	justify-content: space-between;
	&-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
    flex:1;

		.label {
			font-size: 18px;
		}
		.num {
			color: #0bf9fe;
			font-weight: bold;
			font-style: italic;
			font-size: 28px;
		}
		.line {
			width: 1px;
			height: 29px;
			border: 1px solid #274574;
			position: absolute;
			left: 0;
			top: 10px;
		}
	}
}
</style>
